(function(){

    puremvc.define
    (
        // CLASS INFO
        {
            name: 'sinewav3.controller.command.state.preparing.PrepareVisualizerCommand',
            parent: puremvc.SimpleCommand
        },

        // INSTANCE MEMBERS
        {
            execute: function (note) {

                let body = note.getBody();
                let mode = body.mode;

                if (mode === sinewav3.fsm.action.VISUALIZE) {
                    console.log("PrepareVisualizerCommand: Preparing visualizer...");

                    let pane = body.pane;
                    let project = body.project;
                    let auto_play = body.auto_play;
                    let getSelectedWorldIndex = body.getSelectedWorldIndex;
                    let audio_progress_callbacks = body.audio_progress_callbacks;

                    // Create and mediate the visualizer component
                    let visualizer = new sinewav3.view.component.VisualizerComponent(project, pane, getSelectedWorldIndex, this.facade.createAudioContext, auto_play, audio_progress_callbacks);
                    let mediator = new sinewav3.view.mediator.VisualizerMediator();
                    mediator.visualizer = visualizer;
                    this.facade.registerMediator( mediator );

                    // Trigger next state
                    this.facade.triggerAction( mode, body );

                    // If not auto-playing the visualization, trigger pause state
                    if (!auto_play) this.facade.triggerAction( sinewav3.fsm.action.PAUSE, null);
                }
            }
        }
    );

})();