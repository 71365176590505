(function(){

    puremvc.define
    (
        // CLASS INFO
        {
            name: 'sinewav3.view.component.ModelViewerComponent',
            constructor: function(model, pane){
                this.model = model;
                this.pane = document.getElementById(pane);
                this.scene = null;
                this.camera = null;
                this.material = null;
                this.renderer = null;
                this.container = null;
                this.closed = false;
            }
        },

        // INSTANCE MEMBERS
        {
            setup: function() {

                // CANVAS SIZE
                const SQUARE = 350;

                // MODEL AND MATERIAL
                let loader = new THREE.OBJLoader();
                this.container = loader.parse(this.model);

                // SCENE AND CAMERA
                this.scene = new THREE.Scene();
                this.scene.background = new THREE.Color( 0xffffff );
                this.camera = new THREE.PerspectiveCamera( 25, SQUARE/SQUARE, 0.1, 10000);
                this.camera.position.z = 1;

                // RENDERER
                this.renderer = new THREE.WebGLRenderer( { antialias: true } );
                this.renderer.setSize( SQUARE, SQUARE );
                this.pane.appendChild( this.renderer.domElement );

                // ADD MATERIAL TO MESH AND CONTAINER TO SCENE
                this.material = new THREE.MeshBasicMaterial( {wireframe: true, color: 0x3F51B5} );
                this.container.traverse( child => { if ( child && child instanceof THREE.Mesh ) child.material = this.material; } );
                this.scene.add(this.container);
                this.animate();

            },

            render: function() {
                this.renderer.render(this.scene, this.camera);
            },

            animate: function() {
                if (!this.closed) {
                    requestAnimationFrame(() => this.animate());
                    this.container.rotation.y += 0.01;
                    this.render();
                }
            },

            close: function() {
                this.closed = true;
            },

            destroy: function() {
                this.container.traverse( object => {
                    if (object) {
                        if (object.geometry) object.geometry.dispose();
                        if (object.material) {
                            if (object.material.map) object.material.map.dispose();
                            object.material.dispose();
                        }
                    }
                });
                this.scene.remove(this.container);
                this.material.dispose();
                this.model = null;
                this.pane = null;
                this.scene = null;
                this.camera = null;
                this.material = null;
                this.renderer = null;
                this.container = null;
            }

         },

        // CLASS MEMBERS
        {}
    );

})();