(function(){

	puremvc.define
	(
		// CLASS INFO
		{
			name: 'sinewav3.controller.command.startup.PrepareStateMachineCommand',
			parent: puremvc.SimpleCommand
		},

		// INSTANCE MEMBERS
		{
			execute: function (note)
			{
				console.log("PrepareStateMachineCommand: Preparing the State Machine");

				// Get the configuration
				var config = sinewav3.fsm.config;
				var injector = new puremvc.statemachine.FSMInjector(config);
				injector.initializeNotifier(this.facade.multitonKey);
				injector.inject();
			}
		}
	);

})();
