(function(){

    puremvc.define
    (
        // CLASS INFO
        {
            name: 'sinewav3.controller.command.state.destroying.ExitCommand',
            parent: puremvc.SimpleCommand
        },

        // INSTANCE MEMBERS
        {
            execute: function (note) {

                // Set breakpoint here to make sure destroy happened properly
                console.log("state/destroying/ExitCommand: About to exit the DESTROYING state...");
            }
        }
    );

})();