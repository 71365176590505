/**
 * <b>The Plugin developer's primary interface to the Sinewav3 visualizer</b>
 *
 * It solves several problems, including:
 *
 * 1) Your Plugin's setup(), render(), and destroy() methods are just anonymous functions
 * that get invoked when required. They need a way to remember things.
 *
 * 2) The World that your Plugin is a part of may have any number of other Plugins
 * actively manipulating objects in the Scene. You don't want them inadvertently affecting
 * your objects though. Or vice versa.
 *
 * 3) Since the user may enter and exit multiple projects during a Sinewav3 session, we must be wary of
 * memory leaks that occur when things aren't properly destroyed.
 *
 * For these reasons, rather than permit working directly with the THREE.js Scene,
 * the VisualizerContext acts as a mediator between your Plugin's code and the World
 * it is a part of.
 *
 * The benefits are:
 *
 * You can store values and functions and make them available across function invocations,
 * access and modify your own Scene objects, and if you register each Geometry, Material, or Object3D
 * you create in setup() or render(), you can later recall them from the VisualizerContext by name.
 * As a bonus, those registered items will be automatically disposed of by the visualizer,
 * in most cases obviating the need for a destroy() function in your Plugin.
 */
(function() {

    var VisualizerContext = (function() {

        function VisualizerContext( plugin_context, modulator_context, scene, camera, ambient, dimension, loadCompleteFunction, getRendererFunction ) {

            let ctx = this;

            // -----------------
            // public properties
            // -----------------

            /**
             * The PluginContext instance.
             *
             * This is the Plugin developer's interface to the Plugin's settings.
             * @member {PluginContext}
             */
            this.plugin     = plugin_context;

            /**
             * The ModulatorContext instance.
             *
             * This is the Plugin developer's interface to the modulation sources.
             * @member {ModulatorContext}
             */
            this.modulators = modulator_context;

            /**
             * Plugin memory object
             *
             * Because setup(), render(), and destroy() are anonymous functions, invoked as needed,
             * a facility is required for remembering values or functions between invocations.
             *
             * For instance, a function can be created once in setup(), stored on context.memory,
             * and called within each render() invocation. This is faster than declaring the function
             * inside the render function on each frame.
             *
             * Similarly, a rotation value could be stored in a variable on context.memory, and then
             * retrieved, used, and incremented within each render() invocation.
             *
             * @member {Object}
             */
            this.memory = {};

            /**
             * Scene Objects
             *
             * These are just the objects your Plugin instance has added to the World's scene, not all
             * the objects in the scene.
             *
             * @member {Array}
             */
            this.scene_objects = [];

            /**
             * Renderer Dimensions
             *
             * Has width and height properties representing the current dimensions of the renderer.
             * The renderer dimensions react to browser resize during visualization but are fixed
             * during video rendering.
             *
             * @member {Object}
             */
            this.dimension = dimension;

            // ------------------
            // private properties
            // ------------------

            let asset_settings = new Map();
            let materials      = new Map();
            let textures       = new Map();
            let geometries     = new Map();
            let objects        = new Map();
            let fonts          = new Map();
            let load_count     = 0;
            let loading        = false;

            // ---------------
            // private methods
            // ---------------

            /**
             * Report loading complete
             * This is a closure passed into the constructor
             * @private
             */
            let loadingComplete = loadCompleteFunction;

            /**
             * Increment the asset load count
             * @private
             */
            let incrementLoadCount = () => { loading = true; load_count++; };

            /**
             * Decrement the asset load count and report completion at zero
             * @private
             */
            let decrementLoadCount = () => {
                load_count--;
                if ( load_count == 0 && loadingComplete ) loadingComplete();
            };

            /**
             * Load any assets this plugin requires
             * @private
             */
            let loadAssets = () => {
                if (this.plugin.settings) {
                    this.plugin.settings.forEach(
                        group => group.settings.forEach(
                            setting => {
                                if ( setting.type === Setting.TYPE.ASSET ) {

                                    // Map the setting to a flattened version for comparison later
                                    let flattened = flattenSetting(setting);
                                    asset_settings.set(setting, flattened);

                                    // Depending on asset type, load and register appropriately
                                    let asset = setting.value;
                                    switch ( asset.type ) {

                                        // Load a cube and register it as a texture
                                        case AssetGroup.TYPE.CUBE:
                                            incrementLoadCount();
                                            let cube_loader = new THREE.CubeTextureLoader();
                                            cube_loader.setCrossOrigin( 'anonymous' );
                                            cube_loader.load(
                                                asset.assets.map(
                                                    asset => asset.url
                                                ),
                                                cube => {
                                                    ctx.registerTexture( setting.name, cube );
                                                    decrementLoadCount();
                                                },
                                                xhr => {},
                                                xhr => console.log( "Error loading Cube Map:", setting.name, asset.name )
                                            );
                                            break;

                                        // Load an image and register it as an image
                                        case Asset.TYPE.IMAGE:
                                            incrementLoadCount();
                                            let image_loader = new THREE.ImageLoader();
                                            image_loader.setCrossOrigin( 'anonymous' );
                                            image_loader.load(
                                                asset.url,
                                                image => {
                                                    let texture = new THREE.Texture(image);
                                                    texture.needsUpdate = true;
                                                    ctx.registerTexture( setting.name, texture );
                                                    decrementLoadCount();
                                                },
                                                xhr => {},
                                                xhr => console.log( "Error loading Image:", setting.name, asset.name )
                                            );
                                            break;

                                        // Load a 3D Model, and register it as a geometry
                                        case Asset.TYPE.MODEL:
                                            incrementLoadCount();
                                            let object_loader = new THREE.OBJLoader();
                                            object_loader.load(
                                                asset.url,
                                                object => {
                                                    ctx.registerObject3D( setting.name, object );
                                                    decrementLoadCount();
                                                },
                                                xhr => {},
                                                xhr => console.log( "Error loading 3D Model:", setting.name, asset.name )
                                            );
                                            break;

                                        // Load a 3D Font, and register it as a font
                                        case Asset.TYPE.FONT:
                                            incrementLoadCount();
                                            let font_loader = new THREE.FontLoader();
                                            font_loader.load(
                                                asset.url,
                                                font => {
                                                    ctx.registerFont( setting.name, font );
                                                    decrementLoadCount();
                                                },
                                                xhr => {},
                                                xhr => console.log( "Error loading Font:", setting.name, asset.name )
                                            );
                                            break;
                                    }
                                }
                            }
                        )
                    );
                }
                if (!loading && loadingComplete) loadingComplete();
            };

            let flattenSetting = setting => (typeof angular != 'undefined')
                ? angular.toJson( setting.toObject() )
                : JSON.stringify( setting.toObject() );


            //-----------------------------
            // privileged lifecycle methods
            //-----------------------------

            /**
             * Have any assets changed?
             *
             * The VisualizerComponent calls on each frame and
             * if an asset setting is changed, this method returns
             * true, indicating that the visualizer should
             * destroy the plugin and set it up again, triggering
             * the load and application of the new asset.
             *
             * This is necessary in visualization mode, so that
             * users can tweak asset settings on the fly.
             *
             * @returns {boolean}
             * @private
             */
            this.assetsHaveChanged = () => {
                let dirty = false;
                asset_settings.forEach(
                    ( old, current ) => {
                        let flattened = flattenSetting(current);
                        if ( old != flattened ) dirty = true;
                    }
                );
                return dirty;
            };

            /**
             * Dispose of materials, textures, and geometry
             * @private
             */
            this.destroy = () => {
                materials.forEach( material => {
                    try {
                        if (material) material.dispose()
                    } catch (e) {
                        console.log('Problem disposing of material in plugin', this.plugin.name);
                    }
                } );
                materials.clear();
                materials = null;

                textures.forEach( texture => {
                    try {
                        if (texture) texture.dispose()
                    } catch (e) {
                        console.log('Problem disposing of texture in plugin', this.plugin.name);
                    }
                } );
                textures.clear();
                textures = null;

                geometries.forEach( geometry => {
                    try {
                        if (geometry) geometry.dispose()
                    } catch (e) {
                        console.log('Problem disposing of geometry in plugin', this.plugin.name);
                    }
                } );
                geometries.clear();
                geometries = null;

                objects.forEach( object => {
                    try {
                        if (object) object.traverse(
                            child => { if (child && child.geometry) child.geometry.dispose(); }
                        );
                    } catch (e) {
                        console.log("Problem disposing of an object's geometry in plugin", this.plugin.name);
                    }
                } );
                objects.clear();
                objects = null;

                fonts.clear();
                fonts = null;

                this.scene_objects.forEach( object => scene.remove( object ) );
                this.scene_objects  = null;

                this.asset_settings = null;
                this.memory         = null;
                this.plugin         = null;
                this.world          = null;
            };


            // ----------------------------
            // privileged methods (Dev API)
            // ----------------------------

            /**
             * Get the visualizer's THREE.WebGLRenderer instance
             *
             * Caution is advised here.
             *
             * You do not need to set width and height of the renderer, as that's handled by the visualizer.
             * Also, by default, clearColor is set to 0xffffff, and antiAlias to true.
             *
             * Beyond the basics, there are other things you may need to configure for desired operation
             * of your plugin, e.g., shadowMap, which make it unreasonable for us to restrict access to this
             * important component.
             *
             * Therefore, make sure you Understand any changes you make to the renderer, as it affects the
             * scene for all plugins.
             */
            this.getRenderer = getRendererFunction;

            /**
             * Get the world's camera
             *
             * This function is intended for Plugins that exclusively manipulate the camera to perform
             * configurable position, rotation, FOV adjustment, or movement functions such as pan or dolly.
             *
             * Plugin developers should keep in mind that there is just one camera to view a World with,
             * and so the visibility of other Plugins in that World's Scene may be affected by any
             * camera manipulations you do. Therefore, be sure to mention any camera manipulation that
             * your Plugin does in its description.
             *
             * CAUTION: You must not store a reference to the camera, because the user can change its
             * type during visualization, causing it to be replaced with a different object.
             * Therefore you must use this method anytime you wish to access the world's camera.
             *
             * EXAMPLE:
             * <pre>
             * setup(context) {
             *
             *   // Store a function to be used each time render() is called
             *   context.memory.transform = function(cam) {
             *
             *     let x, y, z;
             *     if ( context.plugin.getSettingValue('Rotate','Auto Rotate') ) {
             *        x = cam.rotation.x + context.plugin.getSettingValue('Rotate','X Rot Speed');
             *        y = cam.rotation.y + context.plugin.getSettingValue('Rotate','Y Rot Speed');
             *        z = cam.rotation.z + context.plugin.getSettingValue('Rotate','Z Rot Speed');
             *        cam.rotation.set(x, y, z);
             *     }
             *     x = context.plugin.getSettingValue('Translate','X Pos');
             *     y = context.plugin.getSettingValue('Translate','Y Pos');
             *     z = context.plugin.getSettingValue('Translate','Z Pos');
             *     cam.position.set(x, y, z);
             *
             *   };
             *
             * }
             *
             * render(context) {
             *
             *   // Fetch the camera and transform it on each frame
             *   let cam = context.getCamera();
             *   context.memory.transform(cam);
             *
             * }
             *
             * </pre>
             *
             * @returns {Object} A <a target="_blank" href="https://threejs.org/docs/#api/cameras/Camera">THREE.Camera</a> instance
             */
            this.getCamera = () => camera;


            /**
             * Get the world's ambient light
             *
             *
             * Example:
             * <pre>
             *     // Adjust Ambient Light
             *     let ambientLight = context.getAmbient();
             *     ambientLight.color = new THREE.Color( 0x808080 );
             *     ambientLight.intensity = .5;
             * </pre>
             *
             * @returns {Object} An instance of <a target="_blank" href="https://threejs.org/docs/#api/lights/AmbientLight">THREE.AmbientLight</a>
             */
            this.getAmbient = () => ambient;

            /**
             * Add an object to the scene
             *
             * @param {Object} object A <a target="_blank" href="https://threejs.org/docs/#api/core/Object3D">THREE.Object3D</a> instance
             */
            this.addToScene = ( object ) => {
                if ( scene ) {
                    scene.add( object );
                    this.scene_objects.push( object );
                }
            };

            /**
             * Remove a previously added object from the scene
             *
             * @param {Object} object A <a target="_blank" href="https://threejs.org/docs/#api/core/Object3D">THREE.Object3D</a> instance
             */
            this.removeFromScene = ( object ) => {
                if ( scene ) {
                    scene.remove( object );
                    let idx = this.scene_objects.indexOf( object );
                    this.scene_objects.splice( idx );
                }
            };

            /**
             * Register a material
             *
             * Registered materials can later be retrieved by name
             * and will be automatically disposed of when the visualizer exits.
             *
             * @param {string} name A unique name for the material instance, used for retrieval with getMaterial()
             * @param {Object} material An instance of a subclass of <a target="_blank" href="https://threejs.org/docs/#api/materials/Material">THREE.Material</a> i.e., MeshLambertMaterial
             */
            this.registerMaterial = ( name, material ) => materials.set( name, material );

            /**
             * Retrieve a previously registered material
             *
             * @param {string} name The unique name of the material to retrieve
             * @returns {Object} An instance of a subclass of <a target="_blank" href="https://threejs.org/docs/#api/materials/Material">THREE.Material</a> i.e., MeshLambertMaterial
             */
            this.getMaterial = name => materials.get( name );

            /**
             * Register a texture
             *
             * Registered textures can later be retrieved by name
             * and will be automatically disposed of when the visualizer exits.
             *
             * Textures that are configured via Plugin settings will automatically
             * be loaded, created, and registered using the setting name and can be retrieved
             * immediately in setup() or render() using getTexture().
             *
             * @param {string} name A unique name for the texture instance, used for retrieval with getTexture()
             * @param {Object} texture An instance of <a target="_blank" href="https://threejs.org/docs/#api/textures/Texture">THREE.Texture</a> or a subclass thereof
             */
            this.registerTexture = ( name, texture ) => textures.set( name, texture );

            /**
             * Retrieve a previously registered texture
             *
             * @param {string} name The unique name of the texture to retrieve
             * @returns {Object} An instance of a subclass of <a target="_blank" href="https://threejs.org/docs/#api/textures/Texture">THREE.Texture</a>
             */
            this.getTexture = name => textures.get( name );

            /**
             * Register a geometry
             *
             * Registered geometries can later be retrieved by name
             * and will be automatically disposed of when the visualizer exits.
             *
             * @param {string} name A unique name for the geometry instance, used for retrieval with getGeometry()
             * @param {Object} geometry An instance of <a target="_blank" href="https://threejs.org/docs/#api/core/Geometry">THREE.Geometry</a> or a subclass thereof
             */
            this.registerGeometry = ( name, geometry ) => geometries.set( name, geometry );

            /**
             * Get a previously registered geometry
             *
             * @param {string} name The unique name of the geometry to retrieve
             * @returns {Object} An instance of <a target="_blank" href="https://threejs.org/docs/#api/core/Geometry">THREE.Geometry</a> or a subclass thereof
             */
            this.getGeometry = name => geometries.get( name );

            /**
             * Register a 3D object
             *
             * Registered 3D objects can later be retrieved by name
             * and will be automatically disposed of when the visualizer exits.
             *
             * 3D Objects that are configured (as 3D Models) via Plugin settings and will automatically
             * be loaded, created, and registered using the setting name and can be retrieved
             * immediately in setup() or render() using getObject3D().
             *
             * @param {string} name A unique name for the 3D object instance, used for retrieval with getObject3D()
             * @param {Object} object An instance of <a target="_blank" href="https://threejs.org/docs/#api/core/Object3D">THREE.Object3D</a> or a subclass thereof
             */
            this.registerObject3D = ( name, object ) => objects.set( name, object );

            /**
             * Get a previously registered 3D object
             *
             * @param {string} name A unique name of the 3D object instance to retrieve
             * @returns {Object} An instance of <a target="_blank" href="https://threejs.org/docs/#api/core/Object3D">THREE.Object3D</a> or a subclass thereof
             */
            this.getObject3D = name => objects.get( name );

            /**
             * Register a font
             *
             * Registered fonts can later be retrieved by name
             * and will be automatically disposed of when the visualizer exits.
             *
             * Fonts are always configured via Plugin settings, will automatically
             * be loaded, created, and registered using the setting name, and can be retrieved
             * immediately in setup() or render() using getFont(). Consequently, developers should never have to use this method.
             *
             * Fonts must be in <a target="_blank" href="https://gero3.github.io/facetype.js/">JSON format</a>.
             *
             * @param {string} name A unique name for the font instance, used for retrieval with getFont()
             * @param {Object} font An instance of <a target="_blank" href="https://threejs.org/docs/#api/extras/core/Font">THREE.Font</a>
             */
            this.registerFont = ( name, font ) => fonts.set( name, font );

            /**
             * Get a previously registered font
             *
             * @param {string} name The unique name of the font instance to retrieve
             * @returns {Object} An instance of <a target="_blank" href="https://threejs.org/docs/#api/extras/core/Font">THREE.Font</a>
             */
            this.getFont = name => fonts.get( name );

            /**
             * Add a material to all mesh children of the object
             *
             * @param {Object} object An instance of <a target="_blank" href="https://threejs.org/docs/#api/core/Object3D">THREE.Object3D</a> or a subclass thereof
             * @param {Object} material An instance of a subclass of <a target="_blank" href="https://threejs.org/docs/#api/materials/Material">THREE.Material</a> i.e., MeshLambertMaterial
             */
            this.addMaterialToObject3D = function ( object, material ) {
                object.traverse(
                    child => {
                        if ( child && child instanceof THREE.Mesh ) child.material = material;
                    }
                );
            };

            /**
             * Set the background for the scene.
             * Can be set to one of the following:
             *  - a <a target="_blank" href="https://threejs.org/docs/#api/math/Color">THREE.Color</a> which sets the clear color,
             *  - a <a target="_blank" href="https://threejs.org/docs/#api/textures/Texture">THREE.Texture</a> covering the canvas
             *  - a <a target="_blank" href="https://threejs.org/docs/#api/textures/CubeTexture">THREE.CubeTexture</a>, which changes the canvas texture view with the camera
             *
             *  @param {Object} background the background for the scene
             */
            this.setSceneBackground = background => {
                if ( background &&
                    background instanceof THREE.Color ||
                    background instanceof THREE.Texture ||
                    background instanceof THREE.CubeTexture ) scene.background = background;
            };


            // LOAD ASSETS
            loadAssets();
        }

        return VisualizerContext;

    })();

    // Export
    if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
        module.exports = VisualizerContext;
    } else {
        window.VisualizerContext = VisualizerContext;
    }

})();