(function(){

    puremvc.define
    (
        // CLASS INFO
        {
            name: 'sinewav3.controller.command.state.preparing.PrepareViewerCommand',
            parent: puremvc.SimpleCommand
        },

        // INSTANCE MEMBERS
        {
            execute: function (note) {

                var body = note.getBody();
                var mode = body.mode;

                if (mode === sinewav3.fsm.action.VIEW) {
                    console.log("PrepareViewerCommand: Preparing viewer...");

                    // Create the viewer and register a mediator for it
                    let viewer = (body.model)
                        ? new sinewav3.view.component.ModelViewerComponent(body.model, body.pane)
                        : new sinewav3.view.component.FontViewerComponent(body.font, body.pane, body.on_loaded);
                    let mediator = new sinewav3.view.mediator.ViewerMediator();
                    mediator.viewer = viewer;
                    this.facade.registerMediator( mediator );

                    // Trigger next state
                    this.facade.triggerAction( mode, body );
                }
            }
        }
    );

})();