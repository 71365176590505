(function(){

    puremvc.define
    (
        // CLASS INFO
        {
            name: 'sinewav3.controller.command.startup.PrepareControllerCommand',
            parent: puremvc.SimpleCommand
        },

        // INSTANCE MEMBERS
        {
            execute: function (note) {

                console.log("PrepareControllerCommand: Preparing the Controller");

                // Define namespaces
                var action_note   = sinewav3.fsm.action,
                    action_cmd    = sinewav3.controller.command.action,
                    announce_note = sinewav3.fsm.announce,
                    state_cmd     = sinewav3.controller.command.state,
                    facade        = this.facade;

                // Register commands for state machine announcements
                facade.registerCommand( announce_note.CHANGED_WAITING,     state_cmd.waiting.ChangedCommand );
                facade.registerCommand( announce_note.CHANGED_VIEWING,     state_cmd.viewing.ChangedCommand );
                facade.registerCommand( announce_note.CHANGED_PREPARING,   state_cmd.preparing.ChangedCommand );
                facade.registerCommand( announce_note.CHANGED_ANALYZING,   state_cmd.analyzing.ChangedCommand );
                facade.registerCommand( announce_note.CHANGED_RENDERING,   state_cmd.rendering.ChangedCommand );
                facade.registerCommand( announce_note.CHANGED_VISUALIZING, state_cmd.visualizing.ChangedCommand );
                facade.registerCommand( announce_note.CHANGED_PAUSING,     state_cmd.pausing.ChangedCommand );
                facade.registerCommand( announce_note.CHANGED_RESUMING,    state_cmd.resuming.ChangedCommand );
                facade.registerCommand( announce_note.CHANGED_CLOSING,     state_cmd.closing.ChangedCommand );
                facade.registerCommand( announce_note.CHANGED_DESTROYING,  state_cmd.destroying.ChangedCommand );
                facade.registerCommand( announce_note.EXIT_DESTROYING,     state_cmd.destroying.ExitCommand );
            }
        }
    );

})();