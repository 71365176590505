(function(){

	puremvc.define
	(
		// CLASS INFO
		{
			name: 'sinewav3.controller.command.startup.StartupCommand',
			parent: puremvc.MacroCommand
		},

		// INSTANCE MEMBERS
		{
			initializeMacroCommand: function()
			{
				console.log("StartupCommand: About to prepare Model, View, Controller, and State Machine");

				// Startup command namespace
				var startup = sinewav3.controller.command.startup;

				// Add the sub-commands
				this.addSubCommand( startup.PrepareControllerCommand );
				this.addSubCommand( startup.PrepareModelCommand );
				this.addSubCommand( startup.PrepareViewCommand );
				this.addSubCommand( startup.PrepareStateMachineCommand );
			}
		},

		// CLASS MEMBERS
		{
			STARTUP: 'startup'
		}
	);

})();