(function(){

    puremvc.define
    (
        // CLASS INFO
        {
            name: 'sinewav3.controller.command.state.rendering.ChangedCommand',
            parent: puremvc.SimpleCommand
        },

        // INSTANCE MEMBERS
        {
            execute: function (note) {

                console.log("state/rendering/ChangedCommand: State changed to RENDERING");
            }
        }
    );

})();