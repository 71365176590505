(function(){

	puremvc.define
	(
		// CLASS INFO
		{
			name: 'sinewav3.controller.command.state.preparing.ChangedCommand',
			parent: puremvc.MacroCommand
		},

		// INSTANCE MEMBERS
		{
			initializeMacroCommand: function()
			{
				console.log("state/preparing/ChangedCommand: State changed to PREPARING");

				// Preparing command namespace
				var preparing = sinewav3.controller.command.state.preparing;

				// Add the sub-commands
				this.addSubCommand( preparing.PrepareVisualizerCommand );
				this.addSubCommand( preparing.PrepareAnalyzerCommand );
				this.addSubCommand( preparing.PrepareRendererCommand );
				this.addSubCommand( preparing.PrepareViewerCommand );
			}
		}
	);

})();