(function(){

    puremvc.define
    (
        // CLASS INFO
        {
            name: 'sinewav3.view.component.FontViewerComponent',
            constructor: function(font_path, pane, callback){
                this.font_path = font_path;
                this.font = null;
                this.on_loaded = callback;
                this.request = null;
                this.pane = document.getElementById(pane);
                this.scene = null;
                this.camera = null;
                this.material = null;
                this.geometry = null;
                this.renderer = null;
                this.container = null;
                this.closed = false;
            }
        },

        // INSTANCE MEMBERS
        {
            setup: function() {

                let viz = this;

                // LOAD FONT
                let loader = new THREE.FontLoader();
                loader.load(this.font_path, font => {

                    // Callback
                    viz.on_loaded.apply();

                    // FONT
                    viz.font = font;

                    // CANVAS SIZE
                    const SQUARE  = 350;

                    // SCENE AND CAMERA
                    viz.scene = new THREE.Scene();
                    viz.scene.background = new THREE.Color(0xffffff);
                    viz.camera = new THREE.PerspectiveCamera(45, 350 / 200, 0.1, 10000);
                    viz.camera.position.z = 600;
                    viz.camera.position.y = 100;
                    viz.camera.lookAt(new THREE.Vector3(0,0,0));

                    // RENDERER
                    viz.renderer = new THREE.WebGLRenderer({antialias: true});
                    viz.renderer.setSize(350, 200);
                    viz.pane.appendChild(viz.renderer.domElement);

                    // ADD MATERIAL TO MESH AND CONTAINER TO SCENE
                    viz.material = new THREE.MeshBasicMaterial( {wireframe: true, color: 0x3F51B5} );
                    viz.geometry = new THREE.TextBufferGeometry( 'Sinewav3', {
                        font: viz.font
                    });
                    viz.geometry.computeBoundingBox();
                    viz.geometry.computeVertexNormals();
                    let centerOffset = -0.5 * ( viz.geometry.boundingBox.max.x - viz.geometry.boundingBox.min.x );

                    // CONTAINER AND MESH
                    viz.container  = new THREE.Group();
                    viz.container.position.y = 0;
                    let mesh = new THREE.Mesh(viz.geometry, viz.material);
                    mesh.position.set(centerOffset, 0, 0);
                    viz.container.add( mesh );
                    viz.scene.add( viz.container );

                    viz.animate();
                });


            },

            render: function() {
                this.renderer.render(this.scene, this.camera);
            },

            animate: function() {
                if (!this.closed) {
                    this.request = requestAnimationFrame(() => this.animate());
                    this.container.rotation.y += 0.01;
                    this.render();
                } else {
                    cancelAnimationFrame(this.request);
                }
            },

            close: function() {
                this.closed = true;
            },

            destroy: function() {
                this.scene.remove(this.container);
                this.material.dispose();
                this.geometry.dispose();
                this.font = null;
                this.pane = null;
                this.scene = null;
                this.camera = null;
                this.material = null;
                this.container = null;
                this.renderer = null;
            }

         },

        // CLASS MEMBERS
        {}
    );

})();