(function(){

    puremvc.define
    (
        // CLASS INFO
        {
            name: 'sinewav3.controller.command.state.destroying.ChangedCommand',
            parent: puremvc.SimpleCommand
        },

        // INSTANCE MEMBERS
        {
            execute: function (note) {

                console.log("state/destroying/ChangedCommand: State changed to DESTROYING");

                let facade = this.facade;
                let namespace = sinewav3.view.mediator;
                Object.keys(namespace).forEach( mediator => {

                    if (facade.hasMediator(mediator)) {
                        // Remove the appropriate mediator, which will destroy the view component
                        console.log("Destroying " + mediator + "...");
                        this.facade.removeMediator( mediator );
                    }
                });

                // Trigger next state
                this.facade.triggerAction( sinewav3.fsm.action.WAIT );

            }
        }
    );

})();