(function(){

    puremvc.define
    (
        // CLASS INFO
        {
            name: 'sinewav3.view.component.VisualizerComponent',
            constructor: function( project, pane, getSelectedWorldIndex, createAudioContext, auto_play, audio_progress_callbacks ){

                // Enable the Three.js cache
                // Loaded assets will not be freed once the visualizer is exited.
                // The upside is that returning to a project visualization often
                // (as when developing a plugin) the assets will be on hand, and
                // so the visualization will begin almost immediately.
                // TODO: Provide a UI affordance for clearing the cache
                THREE.Cache.enabled = true;

                // Model
                this.project = project;

                // State
                let viz = this;
                this.dimension = {
                    width: pane.offsetWidth,
                    height: pane.offsetHeight
                };
                this.paused = false;
                this.frame_request = null;
                this.audio_component = null;
                this.modulator_context = new ModulatorContext();
                this.createAudioContext = createAudioContext;
                this.getSelectedWorldIndex = getSelectedWorldIndex;
                this.audio_progress_callbacks = audio_progress_callbacks;
                this.current_world = this.getSelectedWorldIndex();
                this.auto_play = auto_play;

                // Privates
                let plugins_loading = 0;
                let audio_loading = 0;
                let building = true;

                // Load the audio assets
                this.loadAudio = function() {
                    this.audio_component = new sinewav3.view.component.AudioAnalyzerComponent(
                        this.project.audio_asset.url,
                        this.modulator_context,
                        this.createAudioContext(),
                        onAudioAssetsLoaded,
                        null,
                        this.audio_progress_callbacks
                    );
                };

                // Begin loading the audio if this project has it.
                if ( this.project.hasAudio() ) {
                    audio_loading++;
                    if (this.auto_play) this.loadAudio();
                }

                function onAudioAssetsLoaded() {
                    audio_loading--;
                    if (viz.auto_play) {
                        setupIfLoadingAndBuildingComplete();
                    } else {
                        viz.start();
                    }
                }

                // Get the visualizer pane and its size
                this.pane = document.getElementById( pane );
                getVisualizerSize();

                // Handle window resize
                window.addEventListener( 'resize', getVisualizerSize, false );
                this.removeResizeListener = () => window.removeEventListener( 'resize', getVisualizerSize, false );

                // Get the visualizer width and height
                function getVisualizerSize() {
                    viz.dimension.width = viz.pane.offsetWidth;
                    viz.dimension.height = viz.pane.offsetHeight;
                    if (viz.renderer) viz.renderer.setSize( viz.dimension.width, viz.dimension.height );
                    if (viz.camera_list) viz.camera_list.forEach( camera => {
                        camera.aspect = viz.dimension.width/viz.dimension.height;
                        camera.updateProjectionMatrix();
                    } );
                }

                // Create camera list
                // camera_list[world_idx] = the Three.js Camera instance for the indexed world
                this.camera_list = project.worlds.map( world => createCamera( world ) );

                // Create the appropriate camera for the given world
                function createCamera( world ){
                    let camera;
                    let cam_settings = world.getSettingGroup( World.SETTING_GROUP.CAMERA );
                    let type    = cam_settings.getSetting( World.SETTING.CAM.TYPE.NAME ),
                        fov     = cam_settings.getSetting( World.SETTING.CAM.FOV.NAME ),
                        aspect  = cam_settings.getSetting( World.SETTING.CAM.ASPECT.NAME ),
                        near    = cam_settings.getSetting( World.SETTING.CAM.NEAR.NAME ),
                        far     = cam_settings.getSetting( World.SETTING.CAM.FAR.NAME ),
                        left    = cam_settings.getSetting( World.SETTING.CAM.LEFT.NAME ),
                        right   = cam_settings.getSetting( World.SETTING.CAM.RIGHT.NAME ),
                        top     = cam_settings.getSetting( World.SETTING.CAM.TOP.NAME ),
                        bottom  = cam_settings.getSetting( World.SETTING.CAM.BOTTOM.NAME),
                        x       = cam_settings.getSetting( World.SETTING.CAM.X.NAME ),
                        y       = cam_settings.getSetting( World.SETTING.CAM.Y.NAME ),
                        z       = cam_settings.getSetting( World.SETTING.CAM.Z.NAME );

                    switch ( type.value ) {
                        case World.SETTING.CAM.TYPE.PERSPECTIVE:
                            camera = new THREE.PerspectiveCamera( fov.value, viz.dimension.width/viz.dimension.height, near.value, far.value );
                            break;

                        case World.SETTING.CAM.TYPE.ORTHOGRAPHIC:
                            camera = new THREE.OrthographicCamera( left.value, right.value, top.value, bottom.value, near.value, far.value );
                            break;
                    }

                    camera.position.x = x.value;
                    camera.position.y = y.value;
                    camera.position.z = z.value;

                    return camera;

                }

                // Prepare ambient list
                // ambient_list[world_idx] = the Three.js AmbientLight instance for the indexed world
                this.ambient_list = [];

                // Create scene list
                // scene_list[world_idx] = the Three.js Scene instance for the indexed world
                this.scene_list = project.worlds.map( ( world, world_idx ) => createScene( world, world_idx ) );

                // Create the appropriate camera and ambient light for the given world
                // The ambient_list is populated as a side-effect
                function createScene( world, world_idx ){

                    // Get the camera and ambient light
                    let camera = viz.camera_list[ world_idx ];
                    let ambient_settings = world.getSettingGroup( World.SETTING_GROUP.AMBIENT );
                    let color = ambient_settings.getSetting( World.SETTING.AMBIENT.COLOR.NAME );
                    let intensity = ambient_settings.getSetting( World.SETTING.AMBIENT.INTENSITY.NAME );
                    let ambient = viz.ambient_list[ world_idx ]  = new THREE.AmbientLight( color.getValueAsColor(), intensity.value );

                    // Create the scene, adding the camera and ambient light
                    let scene = new THREE.Scene();
                    scene.add( camera );
                    scene.add( ambient );

                    return scene;

                }

                // Create the context matrix
                // context_matrix[world_idx][plugin_idx] = the appropriate VisualizerContext for the indexed plugin
                this.context_matrix = project.worlds
                    .map( world => world.getContextList() )
                    .map( ( context_list, world_idx ) =>
                                context_list.map( context  =>
                                    createContext( world_idx, context.plugin )
                            )
                    );

                // Create the context for a specific plugin
                function createContext( world_idx, plugin_ctx ){
                    plugins_loading++;
                    let scene = viz.scene_list[ world_idx ];
                    let camera = viz.camera_list[ world_idx ];
                    let ambient = viz.ambient_list[ world_idx ];
                    return new VisualizerContext(
                        plugin_ctx,
                        viz.modulator_context,
                        scene,
                        camera,
                        ambient,
                        viz.dimension,
                        onPluginAssetsLoaded,
                        () => viz.renderer
                    );
                }

                function onPluginAssetsLoaded() {
                    plugins_loading--;
                    setupIfLoadingAndBuildingComplete();
                }

                // Create the function matrix
                // function_matrix[world_idx][plugin_idx] = object with invokable functions for the indexed plugin
                this.function_matrix = project.worlds.map( world => world.getFunctionList() );

                // Create setup matrix
                // setup_matrix[world_idx][plugin_idx] = fn() to pass appropriate context to the plugin's setup() function
                this.setup_matrix = this.function_matrix.map(
                    ( fn_list, world_idx ) =>
                        fn_list.map( ( funcs, plugin_idx ) =>
                            () => funcs.setup( viz.context_matrix[ world_idx ][ plugin_idx ] )
                        )
                );

                // Create render matrix
                // render_matrix[world_idx][plugin_idx] = fn() to pass appropriate context to the plugin's render() function
                this.render_matrix = this.function_matrix.map(
                    ( fn_list, world_idx ) =>
                        fn_list.map( ( funcs, plugin_idx ) =>
                            () => funcs.render( viz.context_matrix[ world_idx ][ plugin_idx ] )
                        )
                );

                // Create destroy matrix
                // destroy_matrix[world_idx][plugin_idx] = fn() to pass appropriate context to the plugin's destroy() function
                this.destroy_matrix = this.function_matrix.map(
                    ( fn_list, world_idx ) =>
                        fn_list.map( ( funcs, plugin_idx ) =>
                            () => funcs.destroy( viz.context_matrix[ world_idx ][ plugin_idx ] )
                        )
                );

                // Create the renderer
                this.renderer = new THREE.WebGLRenderer( { antialias: true } );
                this.renderer.setSize( this.dimension.width, this.dimension.height );
                this.renderer.setClearColor( 0xffffff );
                this.renderer.setPixelRatio( window.devicePixelRatio );

                // Done building the visualizer component
                building = false;
                setupIfLoadingAndBuildingComplete();

                // Only once all loading and building are done should we move on to the setup phase
                function setupIfLoadingAndBuildingComplete() {
                    if (!building && plugins_loading === 0 &&
                        (!viz.auto_play || viz.auto_play && audio_loading === 0)
                    ) viz.setup();
                }

            }
        },

        // INSTANCE MEMBERS
        {

            /**
             * Log a trapped plugin error on the developer console
             * @param plugin_idx
             * @param fn_name
             * @param e
             */
            plugin_error: function(plugin_idx, fn_name, e) {
                let world = this.project.worlds[ this.current_world ];
                let plugin = world.plugins[ plugin_idx ];
                let message = [ 'WORLD', world.name ,'PLUGIN', plugin.name, fn_name, e ].join( ' > ' );
                console.log( message );
            },

            /**
             * Remove any children of the visualizer pane,
             * (e.g., the 'wave' indeterminate progress animation)
             * and replace with the renderer's DOM element.
             * Run setup() on all plugins in all worlds then start animation
             */
            setup: function() {
                while (this.pane.firstChild) {
                    this.pane.removeChild(this.pane.firstChild);
                }
                this.pane.appendChild( this.renderer.domElement );
                this.setup_matrix.forEach(
                    setup_list => setup_list.forEach(
                        (setup_function, plugin_idx) => {
                            try {
                                setup_function.apply();
                            } catch (e) {
                                this.plugin_error( plugin_idx,'SETUP', e );
                            }
                        }
                    )
                );

                // Start visualizer or just render poster frame
                if (this.auto_play) {
                    this.start();
                } else {
                    this.poster();
                }
            },

            /**
             * Run render() on all plugins in the selected world,
             * then render a frame using that world's camera and scene
             */
            render: function() {
                this.current_world = this.getSelectedWorldIndex();
                this.render_matrix[ this.current_world ].forEach(
                    (render_function, plugin_idx) => {
                        try {
                            render_function.apply()
                        } catch (e) {
                            this.plugin_error( plugin_idx,'RENDER', e );
                        }
                    }
                );

                this.renderer.render(
                    this.scene_list[ this.current_world ],
                    this.camera_list[ this.current_world ]
                );
            },

            /**
             * Run destroy() on all plugins in the selected world.
             */
            destroy: function() {

                // Destroy the audio component if there is one
                if (this.audio_component) this.audio_component.destroy();
                this.audio_component = null;

                // Destroy callbacks
                this.getSelectedWorldIndex = null;
                this.audioProgressCallbacks = null;

                // Destroy the modulator context
                this.modulator_context = null;

                // Call plugin destroy methods for all worlds
                this.destroy_matrix.forEach(
                    destroy_list => destroy_list.forEach(
                        (destroy_function, plugin_idx) => {
                            try {
                                destroy_function.apply()
                            } catch (e) {
                                this.plugin_error( plugin_idx,'DESTROY', e );
                            }
                        }
                    )
                );

                this.destroy_matrix.splice( 0, this.destroy_matrix.length );

                // Destroy all plugin contexts
                this.context_matrix.forEach(
                    context_list => context_list.forEach(
                        context => context.destroy()
                    )
                );

                // Empty the matrices
                this.context_matrix.splice( 0, this.context_matrix.length );
                this.function_matrix.splice( 0, this.function_matrix.length );
                this.setup_matrix.splice( 0, this.setup_matrix.length );
                this.render_matrix.splice( 0, this.render_matrix.length );
                this.destroy_matrix.splice( 0, this.destroy_matrix.length );

                // Remove window resize listener
                this.removeResizeListener();

                // Remove cameras and ambient lights from scenes
                this.scene_list.forEach(
                    (scene, world_idx) => {
                        scene.remove( this.camera_list[ world_idx ] );
                        scene.remove( this.ambient_list[ world_idx ] );
                    }
                );

                // Clear world-indexed lists
                this.scene_list = null;
                this.ambient_list = null;
                this.camera_list = null;

                // Destroy renderer and its DOM element
                let tex_count = this.renderer.info.memory.textures;
                let geo_count = this.renderer.info.memory.geometries;
                if (tex_count > 0) console.log('PLUGIN CLEANUP NEEDED! '+ tex_count +' textures not destroyed');
                if (geo_count > 0) console.log('PLUGIN CLEANUP NEEDED! '+ geo_count +' geometries not destroyed');
                this.renderer.forceContextLoss();
                this.renderer.dispose();
                this.renderer = null;
                while (this.pane.firstChild) {
                    this.pane.removeChild(this.pane.firstChild);
                }

                // Drop references to the pane and the project
                this.pane = null;
                this.project = null;
            },

            /**
             * Run the browser animation callback loop, breaking out when
             * the stopped property has been set to true by the mediator
             */
            animate: function() {
                if (!this.paused) {
                    this.modulator_context.frame++;
                    this.frame_request = requestAnimationFrame( () => this.animate() );
                    this.render();
                    this.checkAssets();  // TODO: Only do this if we're in visualizer mode
                } else {
                    cancelAnimationFrame( this.frame_request );
                }
            },

            /**
             * If any assets have changed for the any of the plugins,
             * destroy the plugin then run setup on it again
             */
            checkAssets: function () {
                let viz = this;
                this.context_matrix.forEach(
                    ( context_list, world_idx ) => {
                        context_list.forEach(
                            (context, plugin_idx) => {
                                if ( context.assetsHaveChanged() ) {

                                    // Pause the visualizer
                                    viz.pause();

                                    // Run the plugin's destroy function
                                    viz.destroy_matrix[ world_idx ][ plugin_idx ].apply();

                                    // save the world and plugin contexts
                                    let plugin_ctx = context.plugin;

                                    // Remove the old plugin functions
                                    viz.setup_matrix[ world_idx ][ plugin_idx ] = null;
                                    viz.render_matrix[ world_idx ][ plugin_idx ] = null;
                                    viz.destroy_matrix[ world_idx ][ plugin_idx ] = null;

                                    // Destroy the old VisualizerContext
                                    context.destroy();

                                    // Create a new VisualizerContext for the plugin
                                    // NOTE: the function passed to the context causes the setup function to be called and the visualizer to be restarted once
                                    let scene = viz.scene_list[ world_idx ];
                                    let camera = viz.camera_list[ world_idx ];
                                    let ambient = viz.ambient_list[ world_idx ];
                                    viz.context_matrix[ world_idx ][ plugin_idx ] =
                                        new VisualizerContext( plugin_ctx, viz.modulator_context, scene, camera, ambient, viz.dimension, () => {
                                            viz.setup_matrix[ world_idx ][ plugin_idx ].apply();
                                            setTimeout(function(){ viz.start(); }, 350);
                                        }, () => viz.renderer );

                                    // Create new functions for the plugin
                                    let funcs = viz.function_matrix[ world_idx ][ plugin_idx ];
                                    viz.setup_matrix[ world_idx ][ plugin_idx ] = () => funcs.setup( viz.context_matrix[ world_idx ][ plugin_idx ] );
                                    viz.render_matrix[ world_idx ][ plugin_idx ] = () => funcs.render( viz.context_matrix[ world_idx ][ plugin_idx ] );
                                    viz.destroy_matrix[ world_idx ][ plugin_idx ] = () => funcs.destroy( viz.context_matrix[ world_idx ][ plugin_idx ] );

                                }
                            }
                        )
                    }
                );
            },

            /**
             * Start the visualizer on the next animation callback
             */
            start: function() {
                if (this.audio_component) this.audio_component.play();
                this.paused = false;
                this.animate();
            },

            /**
             * Pause the visualizer on the next animation callback
             */
            pause: function() {
                if (this.audio_component) this.audio_component.pause();
                this.paused = true;
            },

            /**
             * Render the poster frame with no audio, then pause
             */
            poster: function() {
                this.render();
                this.pause();
            }

        },

        // CLASS MEMBERS
        {}
    );

})();