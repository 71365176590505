(function(){

    puremvc.define
    (
        // CLASS INFO
        {
            name: 'sinewav3.view.mediator.ViewerMediator',
            parent: puremvc.Mediator
        },

        // INSTANCE MEMBERS
        {
            // When the mediator is registered with the view
            onRegister: function ()
            {
                this.logMessage("registered.");
                this.viewer.setup();
            },

            // The mediator is being removed, destroy the component
            onRemove: function ()
            {
                this.logMessage("removed.");
                this.viewer.destroy();
                this.viewer = null;
            },

            listNotificationInterests: function () {
                return [
                    sinewav3.fsm.announce.ENTER_CLOSING
                ];
            },

            handleNotification: function(note){
                switch (note.getName()) {
                    case sinewav3.fsm.announce.ENTER_CLOSING:
                        this.viewer.close();
                        break;
                }
            },

            // Log a message with this mediator name prepended
            logMessage: function( message ) {
                console.log( this.getMediatorName() + ": " + message);
            },

            get viewer() {
                return this.setViewComponent;
            },

            set viewer(component) {
                this.setViewComponent(component);
            }

        },

        // STATIC MEMBERS
        {
            NAME: 'ViewerMediator'
        }
    );

})();