/**
 * <b>The Plugin developer's interface to the modulation sources.</b>
 *
 * A reference to the ModulatorContext is available to all Plugins
 * in a given world via the VisualizerContext as <b>context.modulators</b>.
 *
 * Modulator values change over time and can be used
 * to modulate various aspects of a Plugin's output.
 *
 * For instance, rather than decide that an object's scale will
 * be modulated by the audio's bass level as you might in a demo,
 * in Sinewav3, you instead expose a Setting of type Modulator in
 * your Plugin, allowing the user to decide whether to modulate
 * that object's scale with the audio bass level or perhaps the
 * overall volume, if that's more effective based on the audio
 * track they are using.
 *
 * Available modulation sources that naturally change include
 * the audio bands and channels as well as the frame number.
 *
 * Another modulation option is customized easers. By specifying
 * the type of ease, beginning value, ending value, number of steps,
 * and optional looping type, you get a customized closure that
 * you can store and then call during each render invocation. An easer
 * returns the next step between the start and end values each
 * time it is called.
 *
 * For instance, say you have a particle system that emits sparks
 * which get bright (slowly, faster, then slowing again), then dim quickly
 * before disappearing. Create two easers for each spark. Control the 'flash'
 * of brightness using an 'exponential in/out' ease with no
 * loop. When that easer returns the maximum value, remove it and dim the
 * spark with the other easer - a non-looping 'ease out quint' going from
 * the maximum value back to the minimum. When that easer returns
 * the maximum value, you remove the particle from the scene and
 * ditch its second easer.
 *
 * See http://easings.net/ for examples of the supported types.
 */
(function() {

    let ModulatorContext = (function() {

        function ModulatorContext(  ) {

            this.audio_bass  = 0;
            this.audio_mid   = 0;
            this.audio_high  = 0;
            this.audio_left  = 0;
            this.audio_right = 0;
            this.audio_vol   = 0;
            this.frame       = 0;
        }


        /**
         * Get a modulator value by name.
         *
         * Modulator values are floating point numbers that fall within
         * the range of 0 to 1.
         *
         * When a Setting of type Modulator is defined for a Plugin, the
         * user chooses a source of modulation data from a dropdown
         * or accepts the sane selection specified by the developer.
         *
         * It is usually a good idea to expose a companion multiplier Setting
         * for every modulator Setting your Plugin exposes. Then, in the Plugin's
         * render function, you can retrieve and multiply the two values before
         * applying them. This allows the user to specify how much influence
         * the modulator value will have.
         *
         * For example, consider a Plugin with a SettingGroup called 'Scale',
         * which contains:
         * - A Setting called 'Mod Source' of type Modulator
         * - A Setting called 'Mod Amount' type Integer
         *
         * <pre>
         *     // Get a reference to the mesh to be scaled, in this case from context.memory.
         *     let mesh = context.memory.mesh;
         *
         *     // Get the name of the currently selected modulation source
         *     let mod_name = context.plugin.getSettingValue('Scale', 'Mod Source');
         *
         *     // Get value of the multiplier
         *     let mod_amount = context.plugin.getSettingValue('Scale', 'Mod Amount');
         *
         *     // Multiply the selected modulator's value by the multiplier value
         *     let product = <b>context.modulators.getModulatorValue(</b> mod_name <b>)</b> * mod_amount;
         *
         *     // Set the scale of the mesh to the product of the modulator and its multiplier
         *     mesh.scale.set( product, product, product );
         * </pre>
         *
         * @param {string} name the name of the modulator value to retrieve
         * @returns {number} a floating point number between 0 and 1 that is the current value of the modulator
         */
        ModulatorContext.prototype.getModulatorValue = function(name) {
            let modulator_value = 0;

            switch ( name ) {

                case Setting.MODULATORS.LEFT:
                    modulator_value = this.audio_left;
                    break;

                case Setting.MODULATORS.RIGHT:
                    modulator_value = this.audio_right;
                    break;

                case Setting.MODULATORS.VOL:
                    modulator_value = this.audio_vol;
                    break;

                case Setting.MODULATORS.BASS:
                    modulator_value = this.audio_bass;
                    break;

                case Setting.MODULATORS.MID:
                    modulator_value = this.audio_mid;
                    break;

                case Setting.MODULATORS.HIGH:
                    modulator_value = this.audio_high;
                    break;
            }

            return modulator_value;
        };

        /**
         * Get the current frame number
         * @returns {number}
         */
        ModulatorContext.prototype.getCurrentFrame = function() {
            return this.frame;
        };

        /**
         * Get a closure that returns the next value for the defined ease each time it's called.
         *
         * The valid ease types can be referenced by these constants:
         *                 Setting.EASES.BACK_IN,
         *                 Setting.EASES.BACK_OUT,
         *                 Setting.EASES.BACK_IO,
         *                 Setting.EASES.BOUNCE_IN,
         *                 Setting.EASES.BOUNCE_OUT,
         *                 Setting.EASES.BOUNCE_IO,
         *                 Setting.EASES.CIRC_IN,
         *                 Setting.EASES.CIRC_OUT,
         *                 Setting.EASES.CIRC_IO,
         *                 Setting.EASES.CUBIC_IN,
         *                 Setting.EASES.CUBIC_OUT,
         *                 Setting.EASES.CUBIC_IO,
         *                 Setting.EASES.ELAST_IN,
         *                 Setting.EASES.ELAST_OUT,
         *                 Setting.EASES.ELAST_IO,
         *                 Setting.EASES.EXP_IN,
         *                 Setting.EASES.EXP_OUT,
         *                 Setting.EASES.EXP_IO,
         *                 Setting.EASES.LINEAR,
         *                 Setting.EASES.QUAD_IN,
         *                 Setting.EASES.QUAD_OUT,
         *                 Setting.EASES.QUAD_IO,
         *                 Setting.EASES.QUART_IN,
         *                 Setting.EASES.QUART_OUT,
         *                 Setting.EASES.QUART_IO,
         *                 Setting.EASES.QUINT_IN,
         *                 Setting.EASES.QUINT_OUT,
         *                 Setting.EASES.QUINT_IO,
         *                 Setting.EASES.SINE_IN,
         *                 Setting.EASES.SINE_OUT,
         *                 Setting.EASES.SINE_IO
         *
         * The valid loop types can be referenced by these constants:
         *                 Setting.LOOPS.NONE,
         *                 Setting.LOOPS.FORWARD,
         *                 Setting.LOOPS.PING_PONG
         *
         * @param ease_type
         * @param start_val
         * @param end_val
         * @param steps
         * @param loop_type
         *
         * @returns {Function}
         */
        ModulatorContext.prototype.getEaser = function(ease_type, start_val, end_val, steps, loop_type = Setting.LOOPS.NONE) {

            // ----------------------------------------
            // Variables accessible by returned closure
            // ----------------------------------------
            let step = 0;       // Current ease step
            let direction = 1;  // Current ping-pong direction (1 = forward, -1 = backward)
            let easer;

            // Create the easing closure
            switch (ease_type) {
                case Setting.EASES.BACK_IN:
                    easer = () => { easeOn(loop_type); return easeInBack(step, start_val, end_val, steps) };
                    break;

                case Setting.EASES.BACK_OUT:
                    easer = () => { easeOn(loop_type); return easeOutBack(step, start_val, end_val, steps) };
                    break;

                case Setting.EASES.BACK_IO:
                    easer = () => { easeOn(loop_type); return easeInOutBack(step, start_val, end_val, steps) };
                    break;

                case Setting.EASES.BOUNCE_IN:
                    easer = () => { easeOn(loop_type); return easeInBounce(step, start_val, end_val, steps) };
                    break;

                case Setting.EASES.BOUNCE_OUT:
                    easer = () => { easeOn(loop_type); return easeOutBounce(step, start_val, end_val, steps) };
                    break;

                case Setting.EASES.BOUNCE_IO:
                    easer = () => { easeOn(loop_type); return easeInOutBounce(step, start_val, end_val, steps) };
                    break;

                case Setting.EASES.CIRC_IN:
                    easer = () => { easeOn(loop_type); return easeInCirc(step, start_val, end_val, steps) };
                    break;

                case Setting.EASES.CIRC_OUT:
                    easer = () => { easeOn(loop_type); return easeOutCirc(step, start_val, end_val, steps) };
                    break;

                case Setting.EASES.CIRC_IO:
                    easer = () => { easeOn(loop_type); return easeInOutCirc(step, start_val, end_val, steps) };
                    break;

                case Setting.EASES.CUBIC_IN:
                    easer = () => { easeOn(loop_type); return easeInCubic(step, start_val, end_val, steps) };
                    break;

                case Setting.EASES.CUBIC_OUT:
                    easer = () => { easeOn(loop_type); return easeOutCubic(step, start_val, end_val, steps) };
                    break;

                case Setting.EASES.CUBIC_IO:
                    easer = () => { easeOn(loop_type); return easeInOutCubic(step, start_val, end_val, steps) };
                    break;

                case Setting.EASES.ELAST_IN:
                    easer = () => { easeOn(loop_type); return easeInElastic(step, start_val, end_val, steps) };
                    break;

                case Setting.EASES.ELAST_OUT:
                    easer = () => { easeOn(loop_type); return easeOutElastic(step, start_val, end_val, steps) };
                    break;

                case Setting.EASES.ELAST_IO:
                    easer = () => { easeOn(loop_type); return easeInOutElastic(step, start_val, end_val, steps) };
                    break;

                case Setting.EASES.EXP_IN:
                    easer = () => { easeOn(loop_type); return easeInExpo(step, start_val, end_val, steps) };
                    break;

                case Setting.EASES.EXP_OUT:
                    easer = () => { easeOn(loop_type); return easeOutExpo(step, start_val, end_val, steps) };
                    break;

                case Setting.EASES.EXP_IO:
                    easer = () => { easeOn(loop_type); return easeInOutExpo(step, start_val, end_val, steps) };
                    break;

                case Setting.EASES.LINEAR:
                    easer = () => { easeOn(loop_type); return easeLinear(step, start_val, end_val, steps) };
                    break;

                case Setting.EASES.QUAD_IN:
                    easer = () => { easeOn(loop_type); return easeInQuad(step, start_val, end_val, steps) };
                    break;

                case Setting.EASES.QUAD_OUT:
                    easer = () => { easeOn(loop_type); return easeOutQuad(step, start_val, end_val, steps) };
                    break;

                case Setting.EASES.QUAD_IO:
                    easer = () => { easeOn(loop_type); return easeInOutQuad(step, start_val, end_val, steps) };
                    break;

                case Setting.EASES.QUART_IN:
                    easer = () => { easeOn(loop_type); return easeInQuart(step, start_val, end_val, steps) };
                    break;

                case Setting.EASES.QUART_OUT:
                    easer = () => { easeOn(loop_type); return easeOutQuart(step, start_val, end_val, steps) };
                    break;

                case Setting.EASES.QUART_IO:
                    easer = () => { easeOn(loop_type); return easeInOutQuart(step, start_val, end_val, steps) };
                    break;

                case Setting.EASES.QUINT_IN:
                    easer = () => { easeOn(loop_type); return easeInQuint(step, start_val, end_val, steps) };
                    break;

                case Setting.EASES.QUINT_OUT:
                    easer = () => { easeOn(loop_type); return easeOutQuint(step, start_val, end_val, steps) };
                    break;

                case Setting.EASES.QUINT_IO:
                    easer = () => { easeOn(loop_type); return easeInOutQuint(step, start_val, end_val, steps) };
                    break;

                case Setting.EASES.SINE_IN:
                    easer = () => { easeOn(loop_type); return easeInSine(step, start_val, end_val, steps) };
                    break;

                case Setting.EASES.SINE_OUT:
                    easer = () => { easeOn(loop_type); return easeOutSine(step, start_val, end_val, steps) };
                    break;

                case Setting.EASES.SINE_IO:
                    easer = () => { easeOn(loop_type); return easeInOutSine(step, start_val, end_val, steps) };
                    break;

            }

            return easer;

            // ----------------------------------------
            // Functions accessible by returned closure
            // ----------------------------------------

            // Stepper
            function easeOn(loop) {

                switch (loop) {

                    case Setting.LOOPS.NONE:
                        if (step < steps) step++;
                        break;

                    case Setting.LOOPS.FORWARD:
                        step++;
                        if (step > steps) step = 1;
                        break;

                    case Setting.LOOPS.PING_PONG:
                        if (direction === 1) {
                            step++;
                            if (step === steps)  direction = -1;
                        } else {
                            step--;
                            if (step === 1)  direction = 1;
                        }
                        break;
                }

            }

            // Easing
            // t: current step, b: beginning value, e: ending value, d: total steps, s = swing over/under amount

            function easeInBack(t, b, e, d, s) {
                let c = e - b;
                if (s === void 0) {
                    s = 1.70158;
                }
                return c * (t /= d) * t * ((s + 1) * t - s) + b;
            }

            function easeOutBack(t, b, e, d, s) {
                let c = e - b;
                if (s === void 0) {
                    s = 1.70158;
                }
                return c * ((t = t / d - 1) * t * ((s + 1) * t + s) + 1) + b;
            }

            function easeInOutBack(t, b, e, d, s) {
                let c = e - b;
                if (s === void 0) {
                    s = 1.70158;
                }
                if ((t /= d / 2) < 1) {
                    return c / 2 * (t * t * (((s *= 1.525) + 1) * t - s)) + b;
                } else {
                    return c / 2 * ((t -= 2) * t * (((s *= 1.525) + 1) * t + s) + 2) + b;
                }
            }

            function easeInBounce(t, b, e, d) {
                let c = e - b;
                let v;
                v = easeOutBounce(d - t, 0, c, d);
                return c - v + b;
            }

            function easeOutBounce(t, b, e, d) {
                let c = e - b;
                if ((t /= d) < 1 / 2.75) {
                    return c * (7.5625 * t * t) + b;
                } else if (t < 2 / 2.75) {
                    return c * (7.5625 * (t -= 1.5 / 2.75) * t + 0.75) + b;
                } else if (t < 2.5 / 2.75) {
                    return c * (7.5625 * (t -= 2.25 / 2.75) * t + 0.9375) + b;
                } else {
                    return c * (7.5625 * (t -= 2.625 / 2.75) * t + 0.984375) + b;
                }
            }

            function easeInOutBounce(t, b, e, d) {
                let c = e - b;
                let v;
                if (t < d / 2) {
                    v = easeInBounce(t * 2, 0, c, d);
                    return v * 0.5 + b;
                } else {
                    v = easeOutBounce(t * 2 - d, 0, c, d);
                    return v * 0.5 + c * 0.5 + b;
                }
            }

            function easeInCirc(t, b, e, d) {
                let c = e - b;
                return -c * (Math.sqrt(1 - (t /= d) * t) - 1) + b;
            }

            function easeOutCirc(t, b, e, d) {
                let c = e - b;
                return c * Math.sqrt(1 - (t = t / d - 1) * t) + b;
            }

            function easeInOutCirc(t, b, e, d) {
                let c = e - b;
                if ((t /= d / 2) < 1) {
                    return -c / 2 * (Math.sqrt(1 - t * t) - 1) + b;
                } else {
                    return c / 2 * (Math.sqrt(1 - (t -= 2) * t) + 1) + b;
                }
            }

            function easeInCubic(t, b, e, d) {
                let c = e - b;
                return c * (t /= d) * t * t + b;
            }

            function easeOutCubic(t, b, e, d) {
                let c = e - b;
                return c * ((t = t / d - 1) * t * t + 1) + b;
            }

            function easeInOutCubic(t, b, e, d) {
                let c = e - b;
                if ((t /= d / 2) < 1) {
                    return c / 2 * t * t * t + b;
                } else {
                    return c / 2 * ((t -= 2) * t * t + 2) + b;
                }
            }

            function easeInElastic(t, b, e, d) {
                let c = e - b;
                let a, p, s;
                s = 1.70158;
                p = 0;
                a = c;
                if (t === 0) {
                    return b;
                } else if ((t /= d) === 1) {
                    return b + c;
                }
                if (!p) {
                    p = d * 0.3;
                }
                if (a < Math.abs(c)) {
                    a = c;
                    s = p / 4;
                } else {
                    s = p / (2 * Math.PI) * Math.asin(c / a);
                }
                return -(a * Math.pow(2, 10 * (t -= 1)) * Math.sin((t * d - s) * (2 * Math.PI) / p)) + b;
            }

            function easeOutElastic(t, b, e, d) {
                let c = e - b;
                let a, p, s;
                s = 1.70158;
                p = 0;
                a = c;
                if (t === 0) {
                    return b;
                } else if ((t /= d) === 1) {
                    return b + c;
                }
                if (!p) {
                    p = d * 0.3;
                }
                if (a < Math.abs(c)) {
                    a = c;
                    s = p / 4;
                } else {
                    s = p / (2 * Math.PI) * Math.asin(c / a);
                }
                return a * Math.pow(2, -10 * t) * Math.sin((t * d - s) * (2 * Math.PI) / p) + c + b;
            }

            function easeInOutElastic(t, b, e, d) {
                let c = e - b;
                let a, p, s;
                s = 1.70158;
                p = 0;
                a = c;
                if (t === 0) {
                    return b;
                } else if ((t /= d / 2) === 2) {
                    return b + c;
                }
                if (!p) {
                    p = d * (0.3 * 1.5);
                }
                if (a < Math.abs(c)) {
                    a = c;
                    s = p / 4;
                } else {
                    s = p / (2 * Math.PI) * Math.asin(c / a);
                }
                if (t < 1) {
                    return -0.5 * (a * Math.pow(2, 10 * (t -= 1)) * Math.sin((t * d - s) * (2 * Math.PI) / p)) + b;
                } else {
                    return a * Math.pow(2, -10 * (t -= 1)) * Math.sin((t * d - s) * (2 * Math.PI) / p) * 0.5 + c + b;
                }
            }

            function easeInExpo(t, b, e, d) {
                let c = e - b;
                return (t == 0) ? b : c * Math.pow(2, 10 * (t / d - 1)) + b;
            }

            function easeOutExpo(t, b, e, d) {
                let c = e - b;
                return (t == d) ? b + c : c * (-Math.pow(2, -10 * t / d) + 1) + b;
            }

            function easeInOutExpo(t, b, e, d) {
                let c = e - b;
                if (t === 0) {
                    return b;
                }
                if (t === d) {
                    return b + c;
                }
                if ((t /= d / 2) < 1) {
                    return c / 2 * Math.pow(2, 10 * (t - 1)) + b;
                } else {
                    return c / 2 * (-Math.pow(2, -10 * --t) + 2) + b;
                }
            }

            function easeLinear(t, b, e, d) {
                let c = e - b;
                return c * t / d + b;
            }

            function easeInQuad(t, b, e, d) {
                let c = e - b;
                return c * (t /= d) * t + b;
            }

            function easeOutQuad(t, b, e, d) {
                let c = e - b;
                return -c * (t /= d) * (t - 2) + b;
            }

            function easeInOutQuad(t, b, e, d) {
                let c = e - b;
                if ((t /= d / 2) < 1) {
                    return c / 2 * t * t + b;
                } else {
                    return -c / 2 * ((--t) * (t - 2) - 1) + b;
                }
            }

            function easeInQuart(t, b, e, d) {
                let c = e - b;
                return c * (t /= d) * t * t * t + b;
            }

            function easeOutQuart(t, b, e, d) {
                let c = e - b;
                return -c * ((t = t / d - 1) * t * t * t - 1) + b;
            }

            function easeInOutQuart(t, b, e, d) {
                let c = e - b;
                if ((t /= d / 2) < 1) {
                    return c / 2 * t * t * t * t + b;
                } else {
                    return -c / 2 * ((t -= 2) * t * t * t - 2) + b;
                }
            }

            function easeInQuint(t, b, e, d) {
                let c = e - b;
                return c * (t /= d) * t * t * t * t + b;
            }

            function easeOutQuint(t, b, e, d) {
                let c = e - b;
                return c * ((t = t / d - 1) * t * t * t * t + 1) + b;
            }

            function easeInOutQuint(t, b, e, d) {
                let c = e - b;
                if ((t /= d / 2) < 1) {
                    return c / 2 * t * t * t * t * t + b;
                } else {
                    return c / 2 * ((t -= 2) * t * t * t * t + 2) + b;
                }
            }

            function easeInSine(t, b, e, d) {
                let c = e - b;
                return -c * Math.cos(t / d * (Math.PI / 2)) + c + b;
            }

            function easeOutSine(t, b, e, d) {
                let c = e - b;
                return c * Math.sin(t / d * (Math.PI / 2)) + b;
            }

            function easeInOutSine(t, b, e, d) {
                let c = e - b;
                return -c / 2 * (Math.cos(Math.PI * t / d) - 1) + b;
            }

        };

        return ModulatorContext;

    })();

    // Export
    if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
        module.exports = ModulatorContext;
    } else {
        window.ModulatorContext = ModulatorContext;
    }

})();