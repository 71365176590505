(function(){

    puremvc.define
    (
        // CLASS INFO
        {
            name: 'sinewav3.view.mediator.VisualizerMediator',
            parent: puremvc.Mediator
        },

        // INSTANCE MEMBERS
        {
            // When the mediator is registered with the view
            onRegister: function ()
            {
                this.logMessage("registered.");
            },

            // The mediator is being removed, destroy the component
            onRemove: function ()
            {
                this.logMessage("removed.");
                this.visualizer.destroy();
                this.visualizer = null;
            },

            listNotificationInterests: function () {
                return [
                    sinewav3.fsm.announce.ENTER_CLOSING,
                    sinewav3.fsm.announce.CHANGED_PAUSING,
                    sinewav3.fsm.announce.CHANGED_RESUMING
                ];
            },

            handleNotification: function(note){
                switch (note.getName()) {
                    case sinewav3.fsm.announce.ENTER_CLOSING:
                    case sinewav3.fsm.announce.CHANGED_PAUSING:
                        this.visualizer.pause();
                        break;

                    case sinewav3.fsm.announce.CHANGED_RESUMING:
                        if (!this.visualizer.auto_play && this.visualizer.project.hasAudio() && !this.visualizer.audio_component) {
                            this.visualizer.loadAudio();
                        } else {
                            this.visualizer.start();
                        }
                        break;
                }
            },

            // Log a message with this mediator name prepended
            logMessage: function( message ) {
                console.log( this.getMediatorName() + ": " + message);
            },

            get visualizer() {
                return this.setViewComponent;
            },

            set visualizer(component) {
                this.setViewComponent(component);
            }

        },

        // STATIC MEMBERS
        {
            NAME: 'VisualizerMediator'
        }
    );

})();