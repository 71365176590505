(function(){

	puremvc.define
	(
		// CLASS INFO
		{
			name: 'sinewav3.VisualizerFacade',
			parent: puremvc.Facade
		},

		// INSTANCE MEMBERS
		{
			// Start the visualizer
			startup: function ()
			{
				if (!this.initialized)
				{
					this.initialized = true;
					let command = sinewav3.controller.command.startup.StartupCommand;
					this.registerCommand( command.STARTUP, command );
					this.sendNotification( command.STARTUP );
				}
			},

			// Create audio context and unmute by playing an empty sound.
			// This stupid architecture is because of iOS, because it must be
			// done immediately in response to the user tap/click.
			createAudioContext: function() {
				this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
				let source = this.audioContext.createBufferSource();
				source.start(0);
				return this.audioContext;
			},

			// View a 3D model
			viewModel: function(model, pane){
				let mode = sinewav3.fsm.action.VIEW;
				let body = {model: model, mode: mode, pane: pane};
				this.triggerAction(sinewav3.fsm.action.PREPARE, body);
			},

			// View a 3D font
			viewFont: function(font, pane, callback){
				let mode = sinewav3.fsm.action.VIEW;
				let body = {font: font, mode: mode, pane: pane, on_loaded: callback};
				this.triggerAction(sinewav3.fsm.action.PREPARE, body);
			},

			// Analyze audio for project
			analyze: function(asset, pane)
			{
				let mode = sinewav3.fsm.action.ANALYZE;
				let body = {asset: asset, mode: mode, pane: pane};
				this.triggerAction(sinewav3.fsm.action.PREPARE, body);
			},

			// Render project
			render: function(project, pane)
			{
				let mode = sinewav3.fsm.action.RENDER;
				let body = {project: project, mode: mode, pane: pane};
				this.triggerAction(sinewav3.fsm.action.PREPARE, body);
			},

			// Visualize project
			visualize: function(project, pane, getSelectedWorldIndex, auto_play=true, audio_progress_callbacks )
			{
				let mode = sinewav3.fsm.action.VISUALIZE;
				let body = {project: project, mode: mode, pane: pane, getSelectedWorldIndex: getSelectedWorldIndex, auto_play: auto_play, audio_progress_callbacks: audio_progress_callbacks};
				this.triggerAction(sinewav3.fsm.action.PREPARE, body);
			},

			// Pause the visualizer
			pause: function()
			{
				this.triggerAction(sinewav3.fsm.action.PAUSE, null);
			},

			// Resume the visualizer
			resume: function()
			{
				this.triggerAction(sinewav3.fsm.action.RESUME, null);
			},

			// Close the visualizer
			close: function()
			{
				this.triggerAction(sinewav3.fsm.action.CLOSE, null);
			},

			triggerAction: function (action, body) {
				this.sendNotification(puremvc.statemachine.StateMachine.ACTION, body, action);
			}
		},

		// STATIC MEMBERS
		{
			getInstance: function (multitonKey)
			{
				let instance = puremvc.Facade.instanceMap[multitonKey]
					? puremvc.Facade.instanceMap[multitonKey]
					: new sinewav3.VisualizerFacade(multitonKey);
				return instance;
			},

			NAME: 'sinewav3'
		}
	);

})();