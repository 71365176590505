(function(){

    puremvc.define
    (
        // CLASS INFO
        {
            name: 'sinewav3.controller.command.state.analyzing.ChangedCommand',
            parent: puremvc.SimpleCommand
        },

        // INSTANCE MEMBERS
        {
            execute: function (note) {

                console.log("state/analyzing/ChangedCommand: State changed to ANALYZING");
            }
        }
    );

})();