(function(){

    puremvc.define
    (
        // CLASS INFO
        {
            name: 'sinewav3.controller.command.state.viewing.ChangedCommand',
            parent: puremvc.SimpleCommand
        },

        // INSTANCE MEMBERS
        {
            execute: function (note) {

                console.log("state/viewing/ChangedCommand: State changed to VIEWING");

            }
        }
    );

})();