(function(){

	puremvc.define
	(
		// CLASS INFO
		{
			name: 'sinewav3.controller.command.startup.PrepareViewCommand',
			parent: puremvc.SimpleCommand
		},

		// INSTANCE MEMBERS
		{
			execute: function (note)
			{
				console.log("PrepareViewCommand: Preparing the View");

				// Register ONLY long-lived mediators that don't need to be destroyed between reuses
			}
		}
	);

})();