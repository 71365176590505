(function(){

    puremvc.define
    (
        // CLASS INFO
        {
            name: 'sinewav3.view.component.AudioAnalyzerComponent',
            constructor: function( audio_path, modulator_context, audio_context, on_loaded, on_complete, progress_callbacks ){
                let comp                = this;
                this.audio_path         = audio_path;
                this.modulator_context  = modulator_context;
                this.on_loaded          = on_loaded;
                this.on_complete        = on_complete;
                this.loop               = !on_complete; // if no on_complete, loop audio
                this.audio_context      = audio_context;
                this.fftSize            = 2048;
                this.script_buffer_size = 512;
                this.script_node        = null;
                this.analyze_freq       = null;
                this.analyze_left       = null;
                this.analyze_right      = null;
                this.source_node        = null;
                this.gain_node          = null;
                this.playing            = false;
                this.paused             = false;
                this.progress_callbacks = progress_callbacks || {onLoad: message => {}, onProgress: xhr => {}, onError: message => {} };

                // LOAD AND DECODE AUDIO, SETUP NODES
                let loader = new THREE.AudioLoader();
                loader.load( comp.audio_path, buffer => {

                    // ANALYZERS
                    comp.analyze_freq         = comp.audio_context.createAnalyser();
                    comp.analyze_left         = comp.audio_context.createAnalyser();
                    comp.analyze_right        = comp.audio_context.createAnalyser();
                    comp.analyze_freq.fftSize = comp.analyze_left.fftSize = comp.analyze_right.fftSize = comp.fftSize;

                    // SPLITTER
                    comp.splitter             = comp.audio_context.createChannelSplitter();
                    comp.splitter.connect( comp.analyze_left,  0, 0 );
                    comp.splitter.connect( comp.analyze_right, 1, 0 );

                    // SCRIPT NODE
                    comp.script_node          = comp.audio_context.createScriptProcessor( comp.script_buffer_size, 1, 1 );
                    comp.script_node.connect( comp.analyze_left );
                    comp.script_node.connect( comp.analyze_right );

                    // GAIN NODE
                    comp.gain_node = comp.audio_context.createGain();
                    comp.gain_node.gain.setTargetAtTime(0.1, comp.audio_context.currentTime, 0); // 10% volume default
                    comp.gain_node.connect( comp.audio_context.destination );

                    // SOURCE NODE
                    comp.source_node          = comp.audio_context.createBufferSource();
                    comp.source_node.loop     = comp.loop;
                    comp.source_node.buffer   = buffer;
                    comp.source_node.connect( comp.splitter );
                    comp.source_node.connect( comp.analyze_freq );
                    comp.source_node.connect( comp.gain_node );
                    if ( !comp.loop ) comp.source_node.onended = comp.on_complete;

                    // AUDIO PROCESSING CALLBACK
                    comp.process = process;

                    // ON LOADED CALLBACK
                    if (comp.on_loaded) comp.on_loaded.apply();
                    if (comp.progress_callbacks.onLoad) comp.progress_callbacks.onLoad.apply();

                    // AUDIO PROCESS CALLBACK
                    function process() {

                        const SAMPLE_RATE = comp.audio_context.sampleRate;
                        const FFT_SIZE = comp.fftSize;
                        const FREQ_BIN_COUNT = FFT_SIZE / 2;
                        const BIN_WIDTH = SAMPLE_RATE / FREQ_BIN_COUNT;
                        const BASS_MIN_BIN = 0;                              // 0hz       Deep Bass
                        const BASS_MAX_BIN = Math.ceil( 250   / BIN_WIDTH);  // 250 hz    Upper Bass
                        const MID_MIN_BIN  = Math.ceil( 250   / BIN_WIDTH);  // 250 hz    Lower Midrange
                        const MID_MAX_BIN  = Math.ceil( 2000  / BIN_WIDTH);  // 2000 hz   Upper Midrange
                        const HIGH_MIN_BIN = Math.ceil( 2000  / BIN_WIDTH);  // 2000 hz   Lower Treble
                        const HIGH_MAX_BIN = Math.ceil( 20000 / BIN_WIDTH);  // 20000 hz  Upper Treble

                        // get the average for the left channel
                        let array_left = new Uint8Array( FREQ_BIN_COUNT );
                        comp.analyze_left.getByteFrequencyData( array_left );
                        let avg_left = getAverageVolume( array_left );

                        // get the average for the second channel
                        let array_right = new Uint8Array( FREQ_BIN_COUNT );
                        comp.analyze_right.getByteFrequencyData( array_right );
                        let avg_right = getAverageVolume( array_right );

                        // get the frequency spectrum average
                        let array_freq = new Uint8Array( FREQ_BIN_COUNT );
                        comp.analyze_freq.getByteFrequencyData( array_freq );
                        let avg_vol = ( avg_left + avg_right ) / 2;

                        // Update the ModulatorContext, normalizing values to fall within 0 and 256
                        comp.modulator_context.audio_left  = avg_left / 256;
                        comp.modulator_context.audio_right = avg_right / 256;
                        comp.modulator_context.audio_vol   = avg_vol / 256;
                        comp.modulator_context.audio_bass  = getAverageVolume( array_freq.slice( BASS_MIN_BIN, BASS_MAX_BIN ) ) / 256;
                        comp.modulator_context.audio_mid   = getAverageVolume( array_freq.slice( MID_MIN_BIN,  MID_MAX_BIN ) ) / 256;
                        comp.modulator_context.audio_high  = getAverageVolume( array_freq.slice( HIGH_MIN_BIN, HIGH_MAX_BIN ) ) /256;

                        // Get average volume
                        function getAverageVolume ( array ) {
                            let values = 0;
                            let average;

                            let length = array.length;

                            // get all the frequency amplitudes
                            for (let i = 0; i < length; i++) {
                                values += array[i];
                            }

                            average = values / length;
                            return average;
                        }
                    }
                }, comp.progress_callbacks.onProgress, comp.progress_callbacks.onError);

            }
        },

        // INSTANCE MEMBERS
        {
            play: function() {
                if ( !this.playing ) {
                    this.script_node.onaudioprocess = this.process;
                    this.source_node.start(0);
                    this.playing = true;
                    this.paused = false;
                } else if ( this.paused ) {
                    this.paused = false;
                    this.audio_context.resume();
                }
            },

            pause: function() {
                if ( this.playing ) this.audio_context.suspend();
                this.paused = true;
            },

            stop: function() {
                try {
                    this.source_node.stop(0);
                    this.source_node.disconnect( this.splitter );
                    this.source_node.disconnect( this.analyze_freq );
                    this.source_node.disconnect( this.audio_context.destination );
                } catch (e) {}
                this.script_node.onaudioprocess = null;
                this.audio_context.close();
            },

            destroy: function() {
                this.stop();
                this.on_loaded          = null;
                this.on_complete        = null;
                this.audio_path         = null;
                this.audio_context      = null;
                this.script_node        = null;
                this.analyze_freq       = null;
                this.analyze_left       = null;
                this.analyze_right      = null;
                this.source_node        = null;
                this.modulator_context  = null;
            }

        },

        // CLASS MEMBERS
        {}
    );

})();
