(function(){

    puremvc.define
    (
        // CLASS INFO
        {
            name: 'sinewav3.controller.command.state.preparing.PrepareRendererCommand',
            parent: puremvc.SimpleCommand
        },

        // INSTANCE MEMBERS
        {
            execute: function (note) {

                var body = note.getBody();
                var mode = body.mode;

                if (mode === sinewav3.fsm.action.RENDER) {
                    console.log("PrepareRendererCommand: Preparing renderer...");

                    // TODO: implement any prep here...
                    var pane = body.pane;
                    var project = body.project;
                    // ...

                    // Trigger next state
                    this.facade.triggerAction( mode, body );
                }
            }
        }
    );

})();